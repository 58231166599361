import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../pages/asp_png.png';

const Sidebar = () => {
  return (
    <div className="h-screen w-64 bg-gradient-to-b from-gray-100 to-white text-black fixed flex flex-col p-5 shadow-lg border-r border-gray-200">
      {/* Logo Section */}
      <div className="flex flex-col items-center mb-8">
        <div className="p-2 rounded-full shadow-lg">
          <img
            src={logo} 
            alt="Logo"
            className=" rounded-full border-2 border-white"
          />
        </div>
      </div>

      {/* Navigation Links */}
      <ul className="space-y-6 p-6">
        <li>
          <Link
            to="/"
            className="text-lg text-gray-700 hover:text-orange-500 hover:font-semibold transition duration-200"
          >
            BLOG
          </Link>
        </li>
   
      </ul>
    </div>
  );
};

export default Sidebar;
