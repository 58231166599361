import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditBlog = ({ blog, onClose, onUpdate }) => {
  const [title, setTitle] = useState(blog.title);
  const [description, setDescription] = useState(blog.description);
  const [category, setCategory] = useState(blog.category || '');
  const [subCategory, setSubCategory] = useState(blog.subCategory || '');
  const [image, setImage] = useState(blog.image || ''); // Default to existing image or empty
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    // If the blog already has an image, it should be shown immediately
    if (blog.image) {
      setImage(blog.image);
    }
  }, [blog.image]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); 
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`https://api.smartchainstudios.com/api/blogs/${blog._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ title, description, category, subCategory, image }),
      });

      if (!response.ok) throw new Error('Failed to update blog');
      const updatedBlog = await response.json();
      onUpdate(updatedBlog);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded shadow-lg w-[500px]">
        <h2 className="text-xl font-bold mb-4">Edit Blog</h2>
        {error && <p className="text-red-500">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block font-bold mb-2">Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block font-bold mb-2">Description</label>
            <ReactQuill
              theme="snow"
              value={description}
              onChange={setDescription}
              className="w-full px-3 py-2 border rounded"
              rows="4"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block font-bold mb-2">Category</label>
            <input
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              placeholder="e.g., Technology, Health"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block font-bold mb-2">SubCategory</label>
            <input
              type="text"
              value={subCategory}
              onChange={(e) => setSubCategory(e.target.value)}
              className="w-full px-3 py-2 border rounded"
              placeholder="e.g., AI, Nutrition"
            />
          </div>
          <div className="mb-4">
            <label className="block font-bold mb-2">Image</label>
            <button
              type="button"
              className="bg-blue-500 text-white px-4 py-2 rounded"
              onClick={() => document.getElementById('imageInput').click()} // Trigger file input click
            >
              Upload Image
            </button>
            <input
              id="imageInput"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="hidden" 
            />
            {image && (
              <div className="mt-2">
                <p className="text-sm text-gray-500">Preview:</p>
                <img src={image} alt="Preview" className="max-h-40 mt-2 rounded border" />
              </div>
            )}
          </div>
          <div className="flex gap-4">
            <button
              type="submit"
              disabled={loading}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              {loading ? 'Saving...' : 'Save Changes'}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-300 px-4 py-2 rounded"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditBlog;
