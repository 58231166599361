import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './components/sidebar';
import Header from './components/header';
import BlogDashboard from './pages/blogDashboard';
import CreateBlog from './pages/createBlog';
import EditBlog from './pages/editBlog';
import Login from './pages/login';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Track loading state

  // Sync authentication state on app load
  useEffect(() => {
    const user = localStorage.getItem('user');
    setIsAuthenticated(!!user); // Update authentication state
    setIsLoading(false); // Authentication check completed
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('user'); // Clear user data
    setIsAuthenticated(false); // Update authentication state
  };

  // Wait for authentication state to initialize
  if (isLoading) {
    return <div>Loading...</div>; // Show a loading indicator while checking auth state
  }

  return (
    <Router>
      {isAuthenticated ? (
        <div className="flex">
          <Sidebar />
          <div className="flex-1">
            <Header onLogout={handleLogout} />
            <div className="p-4">
              <Routes>
                <Route path="/" element={<BlogDashboard />} />
                <Route path="/create" element={<CreateBlog />} />
                <Route path="/edit/:id" element={<EditBlog />} />
              </Routes>
            </div>
          </div>
        </div>
      ) : (
        <Routes>
          <Route path="/login" element={<Login onLoginSuccess={() => setIsAuthenticated(true)} />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      )}
    </Router>
  );
};

export default App;
