import React from 'react';
import { FaUserCircle } from 'react-icons/fa'; // Importing a user icon from react-icons

const Header = ({ onLogout }) => {
  return (
    <div className="bg-white text-black p-4 border-b border-gray-300 flex justify-between items-center shadow-md">
      {/* Left Section with Logo */}
      <div className="flex items-center">
        <img
          src="/path-to-logo/logo.png" // Replace with your logo path
          alt="Logo"
          className="h-12 w-auto mr-3" // Adjust logo dimensions
        />
        <h1 className="text-xl font-semibold text-gray-800">Aspirant's Ally</h1>
      </div>

      {/* Right Section with Profile and Logout */}
      <div className="flex items-center space-x-4">
        {/* Profile Icon */}
        <div className="flex items-center">
          <img
            src="/path-to-profile/profile.jpg" // Replace with actual profile picture path
            alt="Profile"
            className="h-10 w-10 rounded-full border-2 border-gray-300 shadow-md object-cover" // Profile icon styling
          />
        </div>

        {/* Logout Button */}
        <button
          onClick={onLogout}
          className="bg-blue-500 px-4 py-2 rounded text-sm font-semibold text-white hover:bg-blue-600"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default Header;
