import React, { useState } from 'react';
import Modal from '../components/modal'; 
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const CreateBlog = ({ onCreate }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

//   const handleImageChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setImage(reader.result); 
//       };
//       reader.readAsDataURL(file);
//     }
//   };

const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file); // Store the file object instead of Base64
    }
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
  
    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('category', category);
      formData.append('subCategory', subCategory);
      formData.append('description', description);
      if (image) formData.append('image', image); 
  
      const response = await fetch('https://api.smartchainstudios.com/api/blogs', {
        method: 'POST',
        body: formData, // Send FormData
      });
  
      if (!response.ok) throw new Error('Failed to create blog');
      const newBlog = await response.json();
      onCreate(newBlog);
  
      // Reset form and close modal
      setIsModalOpen(false);
      setTitle('');
      setCategory('');
      setSubCategory('');
      setDescription('');
      setImage('');
    } catch (error) {
      console.error('Error creating blog:', error);
      setError('Error creating blog. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <div>
      <button
        onClick={() => setIsModalOpen(true)}
        className="bg-green-500 text-white px-4 py-2 rounded"
      >
        Create New Blog
      </button>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="max-h-[80vh] overflow-y-auto"> {/* Ensures modal content can scroll */}
          <h2 className="text-xl font-bold mb-4">Add New Blog</h2>
          {error && <p className="text-red-500">{error}</p>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block mb-2">Title</label>
              <input
                type="text"
                className="border rounded w-full p-2"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Category</label>
              <input
                type="text"
                className="border rounded w-full p-2"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Sub Category</label>
              <input
                type="text"
                className="border rounded w-full p-2"
                value={subCategory}
                onChange={(e) => setSubCategory(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Description</label>
              <ReactQuill
                theme="snow"
                value={description}
                onChange={setDescription}
                className="border rounded"
              />
            </div>
            <div className="mb-4">
              <button
                type="button"
                className="bg-blue-500 text-white px-4 py-2 rounded"
                onClick={() => document.getElementById('imageInput').click()} // Trigger file input click
              >
                Upload Image
              </button>
              <input
                id="imageInput"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="hidden" // Hide the actual input, using button above
              />
             {image && (
                <div className="mt-2">
                    <p className="text-sm text-gray-500">Preview:</p>
                    <img
                    src={URL.createObjectURL(image)} // Use URL.createObjectURL for preview
                    alt="Preview"
                    className="max-h-40 mt-2 rounded border"
                    />
                </div>
                )}

            </div>
            <div className="flex gap-4">
              <button
                type="submit"
                disabled={loading}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                {loading ? 'Add Blog....' : 'Create Blog'}
              </button>
              <button
                type="button"
                onClick={() => setIsModalOpen(false)} // Close modal when "Cancel" is clicked
                className="bg-gray-300 px-4 py-2 rounded"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default CreateBlog;
