import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import CreateBlog from './createBlog';
import EditBlog from './editBlog';

const SERVER_URL = 'https://api.smartchainstudios.com/api/';

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editBlog, setEditBlog] = useState(null);

  const handleNewBlog = (newBlog) => {
    setBlogs((prevBlogs) => [newBlog, ...prevBlogs]);
  };

  const handleUpdateBlog = (updatedBlog) => {
    setBlogs((prevBlogs) =>
      prevBlogs.map((blog) => (blog._id === updatedBlog._id ? updatedBlog : blog))
    );
    setEditBlog(null);
  };

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(`${SERVER_URL}blogs`);
        if (!response.ok) throw new Error('Failed to fetch blogs');
        const data = await response.json();
        setBlogs(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${SERVER_URL}blogs/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) throw new Error('Failed to delete blog');
      setBlogs(blogs.filter((blog) => blog._id !== id));
    } catch (error) {
      console.error('Error deleting blog:', error);
      setError('Error deleting blog');
    }
  };

  if (loading) return <p>Loading blogs...</p>;
  if (error) return <p className="text-red-500">{error}</p>;

  return (
    <div className="p-6 ml-[250px]">
      <h2 className="text-2xl font-bold mb-4">Blogs</h2>
      <div className="p-2">
        <CreateBlog onCreate={handleNewBlog} />
      </div>
      <ul>
        {blogs.map((blog) => (
          <li key={blog._id} className="mb-4 p-4 border rounded">
            {/* Display the image */}
            {blog.image && (
              <div className="mb-4 h-[12rem] w-[12rem] border">
                <img
                  src={blog.image}
                  alt={blog.title}
                  className=" object-cover rounded w-full"
                />
              </div>
            )}
            <h3 className="text-xl font-bold">{blog.title}</h3>
            <p>{blog.description}</p>
            <div className="flex gap-4 mt-2">
              <button
                onClick={() => setEditBlog(blog)}
                className="text-blue-500 flex items-center gap-2"
              >
                <FontAwesomeIcon icon={faEdit} />
                Edit
              </button>
              <button
                onClick={() => handleDelete(blog._id)}
                className="text-red-500 flex items-center gap-2"
              >
                <FontAwesomeIcon icon={faTrash} />
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
      {editBlog && (
        <EditBlog
          blog={editBlog}
          onClose={() => setEditBlog(null)}
          onUpdate={handleUpdateBlog}
        />
      )}
    </div>
  );
};

export default BlogList;
