import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "./asp_png.png";

const Login = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      const response = await fetch('https://api.smartchainstudios.com/api/users/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) throw new Error('Invalid credentials');

      const data = await response.json();
      localStorage.setItem('user', JSON.stringify(data.user));
      onLoginSuccess();
      navigate('/'); // Redirect to the dashboard
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="h-screen">
      {/* Header Section */}
      <header className="flex items-center justify-between bg-white shadow px-8 py-4">
        {/* Logo */}
        <a href="/" className="flex items-center">
          <img
            src= {logo}
            alt="Medical Mentor Logo"
            className="h-10" // Adjust the height as needed
          />
          {/* <span className="ml-2 text-lg font-bold text-gray-700">
            Medical Mentor
          </span> */}
        </a>
      </header>

      {/* Login Content */}
      <div className="flex h-[calc(100%-64px)] p-16 rounded">
        {/* Left Section */}
        <div className="w-1/2 bg-white flex flex-col justify-center items-center p-12 shadow-lg">
          <h2 className="text-2xl font-bold mb-6">Log in to your account</h2>
          <p className="text-gray-600 mb-6">
            Welcome! Excel in your NEET journey with Medical Mentor
          </p>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <form className="w-full max-w-sm" onSubmit={handleLogin}>
            <div className="mb-4">
              <label className="block text-sm font-bold mb-2" htmlFor="email">
                Email address *
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-4 py-2 border rounded-md focus:ring focus:ring-blue-300"
                required
              />
            </div>
            <div className="mb-6">
              <label className="block text-sm font-bold mb-2" htmlFor="password">
                Password *
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border rounded-md focus:ring focus:ring-blue-300"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 rounded-md font-bold hover:bg-blue-600"
            >
              Log In
            </button>
          </form>
          <div className="mt-4 text-sm">
            <p>
              Don't have an account?{' '}
              <a href="/signup" className="text-blue-500 font-bold hover:underline">
                Sign Up
              </a>
            </p>
            <p className="mt-2">
              Trouble signing in?{' '}
              <a href="/help" className="text-blue-500 font-bold hover:underline">
                Get Help
              </a>
            </p>
          </div>
        </div>

        {/* Right Section */}
        <div className="w-1/2 bg-gradient-to-br from-blue-600 to-purple-700 text-white flex flex-col justify-center items-center p-12">
          <video
            src="/video.mp4"
            autoPlay
            loop
            muted
            className="max-w-xs mb-6 rounded-lg shadow-lg"
          >
            Your browser does not support the video tag.
          </video>

          <h2 className="text-3xl font-bold mb-4">Get Personalized Counsellor</h2>
          <p className="text-center max-w-md">
            We are here to assist you in achieving your dream medical seat.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
